.homepage__container {
    height: var(--full-height);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    overflow: hidden;
}

.homepage__video {
    position: absolute;
    top: 5%;
    left: -20%;
    width: 90%;
    height: 90%;
    object-fit: cover;
    z-index: -1;
}

.homepage__name-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: var(--second-font);
    font-size: var(--home-title-font-size) !important;
    color: white;
    margin-right: 5%;
    text-align: left;
    gap: 0.5rem;
    z-index: 1;
}

.name-line {
    display: flex;
    gap: 0.5rem;
}

.name-display-italic {
    font-style: italic;
}

.name-line__mobile {
    display: none;
}

.homepage__scroll-prompt {
    display: flex;
    align-items: center;
    font-family: var(--body-font);
    font-size: calc(var(--small-font-size) * 0.8);
    margin-top: 1rem;
    cursor: pointer;
    color: #ffffff;
    text-transform: uppercase;
}

.mouse {
    width: 17px;
    height: 30px;
    border: 2px solid #ffffff;
    border-radius: 60px;
    position: relative;
    padding-left: 10px;
    margin-left: 10px;
}

.mouse::before {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 2s infinite;
}

@keyframes wheel {
    to {
        opacity: 0;
        top: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .homepage__video {
        top: -5%;
        left: -10%;
        width: 100%;
        height: 100%;
    }

    .homepage__name-title {
        font-size: calc(var(--home-title-font-size) * 0.8);
        margin-right: 2%;
    }
}

@media only screen and (max-width: 767px) {
    .homepage__container {
        justify-content: center;
        align-items: center;
        height: 580px;
    }

    .homepage__gif,
    .homepage__video {
        position: absolute;
        top: 35%;
        left: 0.15rem;
        min-width: 120%;
        max-width: 120%;
        object-fit: cover;
    }

    .homepage__name-title {
        font-size: 25px !important;
        align-items: center;
        text-align: center;
        margin: 0;
        margin-top: -45%;
    }

    .name-line {
        display: none;
    }

    .name-line__mobile {
        display: inline;
        top: 35%;
        text-align: center;
        margin: 0;
        margin-top: -50%;
        font-size: 25px !important;
    }

    .homepage__scroll-prompt {
        display: none;
    }

    .mouse {
        width: 15px;
        height: 25px;
        margin-left: 5px;
    }
}
