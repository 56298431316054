.about__container {
    padding-top: 100px;
    margin: 0 auto;
    max-width: 80%;
    gap: 1rem;
    height: var(--full-height) * 1.2;
    padding-bottom: 100px;
}

.about__image {
    flex-shrink: 0;
    width: 25%;
    height: auto;
    float: left;
    margin-right: 3.5rem;
    margin-bottom: 2rem;
    outline: 1px solid rgba(208, 208, 208, 0.6);
    outline-offset: 10px; 
    border-radius: 2rem;
}

.about__text {
    flex: 1;
}

.about__text-section {
    font-size: 25px;
    text-align: left;
    line-height: 1.5;
    word-wrap: break-word;
    margin-top: -20px;
}

h2 {
    font-size: var(--section-title-font-size);
    font-family: var(--second-font);
    text-transform: uppercase;
    margin-top: -6%;
}

.about__tech-title {
    padding-top: 10%;
    padding-bottom: 5%;
    font-size: 30px;
    font-family: var(--second-font);
    text-transform: uppercase;
    text-align: center;
}

.about__tech-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    left: -10%;
    position: relative;
}

.about__tech-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 2rem;
}

.about__tech-section-row {
    display: flex;
    flex-direction: row;
}

.about__tech_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1rem 1.5rem;
}

.about__tech_item:hover {
    -webkit-animation: glow .25s alternate infinite;
    animation: glow .25s alternate infinite;
  }


.about__tech_item-logo{
    height: 5.5rem;
    margin-bottom: 1rem;
}

.about__tech_item-logo:hover {
    -webkit-animation: glow .25s alternate infinite;
    animation: glow .25s alternate infinite;
  }

  @-webkit-keyframes glow {
    0% {
      -webkit-filter: brightness(1);
      filter: brightness(1);
    }
    100% {
      -webkit-filter: brightness(1.2);
      filter: brightness(1.2);
    }
  }
  @keyframes glow {
    0% {
      -webkit-filter: brightness(1);
      filter: brightness(1);
    }
    100% {
      -webkit-filter: brightness(1.2);
      filter: brightness(1.2);
    }
  }

.about__tech_section-title {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 400;
    padding-bottom: 5%;
}


@media (max-width: 767px) {

    .about__container {
        height: calc(var(--full-height-mobile) * 2);
        padding-bottom: 150px;
    }
    .about__image {
        width: 35%;
        float: left;
        margin-right: 1.5rem;
        margin-bottom: 1rem;
        outline-offset: 5px;
    }

    .about__title {
        font-size: var(--section-mobile-title-font-size);
    }

    .about__text-section {
        font-size: 16px !important;
    }

    .about__tech-container {
        flex-direction: column;
        left: 4%
    }

    .about__tech_item{
        width: 50px;
    }

    .about__tech_item-logo{
        height: 3rem;
        margin-bottom: 1rem;
    }

    .about__tech_section-title {
        padding-top: 1rem;
        padding-bottom: 0.75rem;
    }

    .about__tech-section {
        justify-content: center;
    }
}

@media (max-width: 420px) {
    .about__container {
        height: calc(var(--full-height-mobile) * 2.5);
    }
}