.work__container {
    height: var(--full-height);
    padding-top: 100px;
}

.work_title {
    font-size: var(--section-subtitle-font-size);
}

.work__text-section {
    margin: auto;
    min-width: 80%;
    max-width: 80%;
    font-size: 25px;
    display: flex;
    flex-direction: column;
}

.work__item {
    margin-bottom: 30px;
}

.work__company-name {
    font-family: var(--second-font);
    font-size: 30px;
}

.work__details {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  .work__position {
    display: flex;
    width: 273px;
    align-items: center;
  }

.work__location {
    font-style: italic;
    margin-left: 20px;
}

.work__position,
.work__location,
.work__duration {
  margin-right: 15px;
}

hr.solid {
    border-top: 0.5px solid #ccc;
    margin: 5px 0;
    width: 100%;
    opacity: 0.7;
}

@media only screen and (max-width: 767px) {
    .work__container {
        height: calc(var(--full-height-mobile) * 0.8);
    }

    .work__item {
        padding-top: 10px;
        margin-bottom: 15px;
    }

    .work__location {
        font-size: 16px;
    }

    .work__position {
        display: flex;
        width: 150px;
        align-items: center;
      }

    .work__company-name {
        font-size: 25px !important;
    }

    .work__title{
        font-size: calc(var(--section-mobile-title-font-size) * 0.6);
    }
}

/* @media only screen and (max-width: 390px) {
    .work__container {
        padding-top: 80%;
    }
} */