@import url('https://fonts.googleapis.com/css2?family=Metal&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
 }

 :root {
  --header-height: 3.5rem;
  --first-color: hsl(180, 1%, 31%);
  --title-color: hsl(0, 0%, 95%);
  --text-color: hsl(0, 0%, 75%);
  --body-color: hsl(0, 0%, 0%);
  --body-font: "Roboto", sans-serif;
  --second-font: "Metal";
  --third-font: "Source Code Pro";
  --section-title-font-size: 7rem;
  --section-text-font-size: 1.563rem;
  --section-subtitle-font-size: 3rem;
  --nav-font-size: 1rem;
  --small-font-size: 1.25rem;
  --smallest-font-size: 0.8rem;
  --home-title-font-size: 4rem;
  --section-mobile-title-font-size: 3rem;
  --section-mobile-text-font-size: 1.25rem;
  --font-semi-bold: 600;
  --z-fixed: 100;
  --full-height: 100vh;
  --full-height-mobile: 90vh;
 }

 body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
 }
 
 ul {
  list-style: none;
 }
 
 a {
  text-decoration: none;
 }


.crosshair {
  position: fixed;
  pointer-events: none;
  z-index: 999;
}


.crosshair .horizontal {
  position: absolute;
  height: 1px;
  background-color: #ffffff21;
  left: 0; 
}

.crosshair .vertical {
  position: absolute;
  width: 1px;
  background-color: #ffffff21;
  top: 0;
}

.crosshair .horizontal.top {
  width: 100vw;
  top: 50%;
  transform: translateY(-50%);
}

.crosshair .horizontal.bottom {
  width: 100vw;
  top: 50%;
  transform: translateY(-50%);
}

.crosshair .vertical.left {
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
}

.crosshair .vertical.right {
  height: 100vh;
  left: 50%;
  transform: translateX(-50%);
}

.coordinates {
  position: fixed;
  right: 20px;
  top: 70%;
  font-size: var(--smallest-font-size);
  font-family: var(--third-font);
  color: var(--title-color);
  z-index: 1000;
}


@media only screen and (max-width: 767px) {
  .crosshair {
    display: none;
  }

  .coordinates {
    display: none;
  }
}
